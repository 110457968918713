import React, {useEffect, useState} from "react";
import {
    TreeList,
    mapTree,
    extendDataItem
} from "@progress/kendo-react-treelist";
import {Loader, ErrorFallback} from "smart-react";
import "@progress/kendo-theme-default/dist/all.css";
import {ErrorBoundary} from "react-error-boundary";
import {listAppFlowStepByID} from "../Services/AppFlowStepsService";
import './AppFlowSteps.scss';
const subItemsField = "children";
const expandField = "expanded";

/**
 * AppFlowSteps component.
 * Displays a tree list of App flow steps with functionality to add, delete, and manage child steps.
 *
 * @component
 * @returns {React.ReactElement} The AppFlowSteps component.
 */
const AppFlowSteps = (props) => {
    const [state, setState] = useState({
        data: [],
        expanded: []
    });

    useEffect(() => {
        fetchAppFlowStepsById();
    }, []);

    /**
     * Fetches application flow steps by ID and prepares the data.
     * @async
     */
    const fetchAppFlowStepsById = async () => {
        const filter = [
            {
                ColumnName: "UcOssiAfId",
                Op: "eq",
                ColumnValue: props.flow.uc_ossi_af_id
            }
        ];
        const response = await listAppFlowStepByID({
            filter: filter ? filter : [],
            uc_ossi_af_id: props.flow.uc_ossi_af_id
        });
        const initialData = prepareData(response.steps);
        setState((prevState) => ({...prevState, data: initialData}));
    };

    /**
     * Adds the `expandField` to the tree data structure.
     * @param {TreeNode[]} dataTree - The data tree to process.
     * @returns {TreeNode[]} The processed tree with expanded state.
     */
    const addExpandField = (dataTree) => {
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: state.expanded.includes(item.id)
            })
        );
    };

    /**
     * Prepares the data for the tree list.
     * @returns {TreeNode[]} The processed tree data.
     */
    const processData = () => addExpandField(state.data);

    /**
     * Handles tree node expand/collapse changes.
     * @param {Object} e - The event object for expand/collapse.
     */
    const onExpandChange = (e) => {
        const itemId = e.dataItem.id;
        setState((prevState) => ({
            ...prevState,
            expanded: e.value
                ? prevState.expanded.filter((id) => id !== itemId)
                : [...prevState.expanded, itemId]
        }));
    };

    const columns = [
        {field: "UcOssiAfId", title: "Flow ID", expandable: true},
        {field: "UcOssiAfStepId", title: "Step ID"},
        {field: "StepType", title: "Step Type"}
    ];
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className="container mx-auto">
                <React.Suspense fallback={<Loader/>}>
                    <TreeList
                        data={processData()}
                        columns={columns}
                        expandField={expandField}
                        subItemsField={subItemsField}
                        onExpandChange={onExpandChange}
                        style={{overflow: 'auto'}}
                        tableProps={{style: {width: '100%'}}}
                    />
                </React.Suspense>
            </div>
        </ErrorBoundary>
    );
};

const prepareData = (data) => {
    return data.map((item) => {
        const children = item.ApplicationFlow?.ApplicationFlowSteps
            ? prepareData(item.ApplicationFlow.ApplicationFlowSteps)
            : [];

        return {
            ...item,
            id: item.UcOssiAfStepId || item.UcOssiAfId,
            hasChildren: children.length > 0,
            children
        };
    });
};
export default AppFlowSteps;